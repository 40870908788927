.text-outline {
    -webkit-text-stroke-width: 0.05px;
    -webkit-text-stroke-color: white;
}

.flash {
    animation: flashAnimation 1s;
}

@keyframes flashAnimation {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    40% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.line-div {
    position: relative; /* Ensure lines are positioned relative to this container */
    width: 100%;
    height: auto; /* or set a fixed height */
}

.line {
    position: absolute;
    left: 0;
    width: 100%;
    background-color: lightgray; /* Color of the line */
}

.row-table .google-visualization-table-table th {
    display: none;
}
.row-table .google-visualization-table-table td {
    border: none !important;
    background-color: white !important;
}
.row-table .google-visualization-table-table td:first-child {
    font-weight: 600;
    color: #74829b;
}

.react-grid-item.react-draggable-dragging {
    z-index: 500 !important;
}

.twitter-picker {
    border-width: 1px !important;
    border-color: rgb(226 232 240) !important;
    border-radius: 0.75rem !important; /* 12px */
    box-shadow: none !important;
}

.twitter-picker > div > span > div {
    border: 1px solid lightgray !important;
}
