/* .item must be first */
.page-item {
    align-items: center;
    color: #9ca3af;
    display: flex;
    font-size: 14px;
    height: fit-content;
    width: fit-content;
    justify-content: center;
    margin: 0 0.4rem;
    font-weight: 600;

    user-select: none;
    -moz-user-select: none;
    -webkit-text-select: none;
    -webkit-user-select: none;
}

.page-item:hover {
    color: rgb(22, 119, 255);
    font-weight: 600;
}

.disabled-page {
    color: #808e9b;
}

.page-item.active {
    border: solid 1px rgb(105, 177, 255);
    border-radius: 100%;
    height: 30px;
    width: 30px;
    font-weight: 600;
    background-color: rgb(230, 244, 255);
    color: rgb(105, 177, 255);
    box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
}

/* .page-link {
} */

.previous {
    border-radius: 10px;
    align-items: center;
    color: rgb(22, 119, 255);
    background: transparent;
    display: flex;
    font-size: 14px;
    height: fit-content;
    width: fit-content;
    justify-content: center;
    padding: 0;
    margin: 0 0.5rem;
    font-weight: 600;

    user-select: none;
    -moz-user-select: none;
    -webkit-text-select: none;
    -webkit-user-select: none;
}

.previous:hover {
    color: rgb(230, 244, 255);
    font-weight: 600;
}

.next {
    border-radius: 10px;
    align-items: center;
    color: rgb(22, 119, 255);
    background: transparent;
    display: flex;
    font-size: 14px;
    height: fit-content;
    width: fit-content;
    justify-content: center;
    padding: 0;
    margin: 0 0.5rem;
    font-weight: 600;

    user-select: none;
    -moz-user-select: none;
    -webkit-text-select: none;
    -webkit-user-select: none;
}

.next:hover {
    color: rgb(230, 244, 255);
    font-weight: 600;
}

.pagination {
    align-items: center;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: end;
    list-style-type: none;
    position: relative;
    padding: 0 0.5rem;
    margin: 0;
}
