.google-visualization-charteditor-dialog {
    z-index: 1000 !important;
    width: fit-content !important;
    border: 0 !important;
    border-radius: 10px !important;
    height: fit-content !important;
}

.modal-dialog {
    z-index: 1000 !important;
}

.modal-dialog-buttons {
    display: flex;
    justify-content: end;
    align-items: center;
    position: relative;
    margin-top: 20px !important;
    width: 100%;
}

#google-visualization-charteditor-preview-div-wrapper {
    border-left: 1px solid rgb(230, 244, 255);
}

#google-visualization-charteditor-chart-type-categories.goog-tab-bar {
    height: 100% !important;
    padding: 10px 5px !important;
}

#google-visualization-charteditor-chart-type-categories {
    border-right: 1px solid rgb(230, 244, 255) !important;
}

.google-visualization-charteditor-category.goog-tab {
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
    background: #fff;
    height: 30px !important;
    margin: 2px 0 !important;
    padding: 1px 5px !important;
    position: relative;
    text-decoration: none;
}
.google-visualization-charteditor-category.goog-tab {
    border-radius: 5px;
}

:hover.google-visualization-charteditor-category.goog-tab {
    background-color: rgb(230, 244, 255) !important;
}

.google-visualization-charteditor-mini {
    margin-right: 8px !important;
}

.google-visualization-charteditor-category-label {
    color: #666;
    font-size: 14px;
    padding: 0px;
    margin: 0px;
    position: relative;
    top: 0px !important;
    text-decoration: inherit;
}

.goog-tab-selected {
    color: rgb(22, 119, 255) !important;
    background-color: rgb(230, 244, 255) !important;
    border-radius: 10px 10px 0 0 !important;
    box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05) !important;
    border-color: rgb(230, 244, 255) !important ;
}

#google-visualization-charteditor-chart-type-categories.goog-tab-bar
    > .google-visualization-charteditor-category.goog-tab-selected {
    background-color: rgb(105, 177, 255) !important;
    border-radius: 5px !important;
}

:hover.google-visualization-charteditor-category.goog-tab-selected {
    background-color: rgb(105, 177, 255) !important;
    border-radius: 5px !important;
}

#google-visualization-charteditor-preview-div-chart {
    width: 100% !important;
    height: 100% !important;
}

.google-visualization-charteditor-preview-td {
    width: fit-content !important;
}

#google-visualization-charteditor-preview-div-chart {
    border-radius: 10px;
}

#google-visualization-charteditor-options-panel
    .google-visualization-charteditor-section {
    width: 100% !important;
}

#google-visualization-charteditor-preview-div-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    top: 13px !important;
}

#google-visualization-charteditor-panel-navigate-div {
    border-bottom: 1px solid #e4e4e4 !important;
}

.google-visualization-charteditor-small-input {
    border-radius: 5px !important;
}

.goog-flat-menu-button {
    height: fit-content !important;
}

.google-visualization-charteditor-thumbnail.jfk-radiobutton {
    border-radius: 5px !important;
}

.google-visualization-charteditor-thumbnail.jfk-radiobutton-checked {
    border: 4px solid rgb(105, 177, 255) !important;
    height: 78px;
    outline-color: #fff;
    width: 120px;
}

.google-visualization-charteditor-thumbnail.jfk-radiobutton {
    border: 4px solid #ddd;
    display: block;
    margin: 5px !important;
    padding: 1px !important;
    height: 78px !important;
    width: 120px !important;
}

.modal-dialog-buttons button {
    background-color: #fff !important;
    background-image: none !important;
    border: 1px solid #e4e4e4 !important;
    border-radius: 0.5rem !important;
    color: rgb(22, 119, 255) !important;
    box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05) !important;
    cursor: pointer !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    height: 35px !important;
}

.modal-dialog-title {
    color: #6e7d92 !important;
    font-weight: 600 !important;
}

.google-visualization-charteditor-panel {
    border-radius: 5px !important;
}

.google-visualization-charteditor-section-title {
    color: #6e7d92 !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    padding: 5px 0;
}

.google-visualization-charteditor-link {
    color: #357ae8 !important;
}

:hover.google-visualization-charteditor-panel-navigation-cell.goog-tab {
    color: rgb(22, 119, 255) !important;
}

.google-visualization-charteditor-panel-scroll {
    border: 1px solid #e4e4e4 !important;
    border-radius: 10px !important;
}

.google-visualization-charteditor-input {
    border-radius: 5px !important;
    outline: none;
    color: #647081 !important;
    padding: 6px !important;
}
:hover.google-visualization-charteditor-input {
    border: 1px solid rgb(105, 177, 255);
}
:focus.google-visualization-charteditor-input {
    border: 1px solid rgb(105, 177, 255);
}

.google-visualization-charteditor-small-input {
    border-radius: 5px !important;
    outline: none;
    color: #647081 !important;
}
:hover.google-visualization-charteditor-small-input {
    border: 1px solid rgb(105, 177, 255);
}
:focus.google-visualization-charteditor-small-input {
    border: 1px solid rgb(105, 177, 255);
}

.google-visualization-charteditor-multi-section-title {
    color: rgb(22, 119, 255) !important;
    width: 100% !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f9ff !important;
}

.google-visualization-charteditor-chart-type-subtypes {
    overflow: auto !important;
}

.google-visualization-charteditor-dialog .goog-flat-menu-button {
    border-radius: 5px !important;
    color: #647081 !important;
    line-height: 16px !important;
    height: 28px !important;
    width: fit-content;
    cursor: pointer;
}

.goog-flat-menu-button.goog-flat-menu-button-focused {
    border: 1px solid rgb(105, 177, 255) !important;
}

.google-visualization-table-table th {
    color: rgb(100 116 139);
}

.google-visualization-charteditor-combobox .goog-toolbar-combo-button-input {
    width: 34px !important;
    height: 28px !important;
}

.jfk-checkbox {
    height: 12px !important;
    margin: 0 4px 1px 1px !important;
    border-radius: 15% !important;
    box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
    cursor: pointer;
}

.google-visualization-charteditor-color .goog-flat-menu-button-indicator {
    padding: 0 4px !important;
    margin: 0 !important;
}

.google-visualization-charteditor-combobox.goog-toolbar-combo-button {
    border-radius: 12% !important;
    padding: 0 !important;
    height: 28px !important;
    cursor: pointer;
}

.google-visualization-charteditor-toggle.jfk-button {
    cursor: pointer;
    height: 28px !important;
    border-radius: 12%;
}

.jfk-radiobutton-radio {
    cursor: pointer !important;
}

.jfk-radiobutton .jfk-radiobutton-label {
    cursor: pointer !important;
}

.goog-toolbar-combo-button-caption {
    margin: 0 !important;
    padding: 0 !important;
}

.google-visualization-charteditor-mid-input {
    border-radius: 4px !important;
}

.goog-flat-menu-button-caption {
    width: fit-content !important;
    height: fit-content !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.google-visualization-charteditor-series-color-items {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

#google-visualization-charteditor-font-style-title,
#google-visualization-charteditor-font-style-legend,
#google-visualization-charteditor-font-style-titlex,
#google-visualization-charteditor-font-style-axisx,
#google-visualization-charteditor-font-style-titley,
#google-visualization-charteditor-font-style-axisy {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.google-visualization-charteditor-input-label {
    display: inline-block !important;
}

.google-visualization-charteditor-align-start {
    display: inline-block !important;
}

.goog-inline-block {
    display: inline-block !important;
    vertical-align: top;
}

[id^='google-visualization-charteditor-series-select-target-axis-series-'] {
    display: flex;
    margin: 0px 0px 5px 3px !important;
}

#google-visualization-charteditor-series-items {
    display: flex;
}

[id^='google-visualization-charteditor-series-select-error-type-series-'] {
    margin: 5px 0px 0px 3px !important;
    width: 120px !important;
}

#google-visualization-charteditor-errorbars {
    margin-top: 15px;
}

.google-visualization-charteditor-panel-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 580px !important;
}

.google-visualization-charteditor-name-input {
    display: none;
    background-color: #ffffff; /* Replace with the color for "bg-backgrounds-white" */
    color: #64748b; /* Replace with the color for "text-texts-secondary" */
    padding: 8px !important; /* 2.5 translates approximately to 10px in most setups */
    border-radius: 12px; /* For "rounded-xl" */
    border: 1px solid transparent; /* Ensures a border is there but initially transparent */
    outline: none !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    border-color: lightgray !important; /* Replace with the color for "hover:border-backgrounds-primary300" */
}

.google-visualization-charteditor-name-input:hover {
    outline: none !important;
    border-color: rgb(
        22,
        119,
        255
    ) !important; /* Replace with the color for "hover:border-backgrounds-primary300" */
}

.google-visualization-charteditor-name-input:focus {
    border-color: rgb(
        105,
        177,
        255
    ) !important; /* Replace with the color for "focus:outline-backgrounds-primary200" */
}

#google-visualization-charteditor-help-link {
    display: none !important;
    cursor: pointer;
}
