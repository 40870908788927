.animated-background {
    background: linear-gradient(
        228deg,
        rgb(230, 244, 255),
        rgb(105, 177, 255),
        rgb(165 243 252)
    );
    background-size: 200% 200%;

    -webkit-animation: AnimationName 20s ease infinite;
    -moz-animation: AnimationName 20s ease infinite;
    -o-animation: AnimationName 20s ease infinite;
    animation: AnimationName 20s ease infinite;
}

@-webkit-keyframes AnimationName {
    0% {
        background-position: 93% 0%;
    }
    50% {
        background-position: 0% 100%;
    }
    100% {
        background-position: 93% 0%;
    }
}
@-moz-keyframes AnimationName {
    0% {
        background-position: 93% 0%;
    }
    50% {
        background-position: 0% 100%;
    }
    100% {
        background-position: 93% 0%;
    }
}
@-o-keyframes AnimationName {
    0% {
        background-position: 93% 0%;
    }
    50% {
        background-position: 0% 100%;
    }
    100% {
        background-position: 93% 0%;
    }
}
@keyframes AnimationName {
    0% {
        background-position: 93% 0%;
    }
    50% {
        background-position: 0% 100%;
    }
    100% {
        background-position: 93% 0%;
    }
}

input[type='radio']:after {
    width: 15.5px;
    height: 15.5px;
    border-radius: 15.5px;
    top: -3px;
    left: -1px;
    position: relative;
    background-color: #ffffff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #dbdbdb;
    box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05) !important;
}

input[type='radio']:checked:after {
    width: 15.5px;
    height: 15.5px;
    border-radius: 15.5px;
    top: -3px;
    left: -1px;
    position: relative;
    background-color: rgb(9, 88, 217);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #dbdbdb;
    box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05) !important;
}

option:checked {
    background-color: rgb(9, 88, 217) !important;
    color: white;
}

mark.chapters {
    background-color: #6effa3;
    color: black;
    border: 1px solid rgb(0, 189, 0);
    padding: 0.2px 0.5px;
}

mark.chunks {
    background-color: #ffe65a;
    color: black;
    /* border: 1px solid rgb(230, 207, 1); */
    padding: 0.2px 0.5px;
}

mark.tables {
    background-color: #ff8b8b;
    color: black;
    border: 1px solid rgb(255, 65, 65);
    padding: 0.2px 0.5px;
}
