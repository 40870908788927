/* Individual thumbnail container */
.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    transition: transform 0.2s ease;
    position: relative;
}

.react-viewer-close {
    display: none !important;
}

.react-viewer-image {
    border: 5px solid white;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
}

.react-viewer-canvas {
    background-color: #f9fafc;
}

.react-viewer-navbar {
    display: none !important;
    background-color: rgb(0 0 0 / 20%);
}

.react-viewer-toolbar {
    margin-bottom: 1rem !important;
}

.react-viewer-list {
    height: 4rem !important;
}

.react-viewer-list > li.active > img {
    opacity: 1;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    border: 2px solid #0a78f4;
}

.react-viewer-list > li {
    width: fit-content !important;
    height: 100% !important;
    padding: 1px !important;
}

.react-viewer-list > li > img {
    width: fit-content !important;
    height: 96% !important;
    margin: 1px !important;
    padding: 1px !important;
    border: 1px solid white;
}

.react-viewer-attribute {
    font-weight: 600;
    color: white !important;
    text-shadow: -1px -1px 0 grey, 1px -1px 0 grey, -1px 1px 0 grey,
        1px 1px 0 grey;
    letter-spacing: 2px;
}

.react-viewer-mask {
    z-index: 0 !important;
}

.react-viewer-close .react-viewer-btn {
    z-index: 10 !important;
}

.react-viewer-canvas {
    z-index: 5 !important;
}

.react-viewer-footer {
    z-index: 5 !important;
}

.circle-loading {
    position: absolute !important;
    top: 40% !important;
    left: 46.5% !important;
    background-color: #e6e6e8 !important;
}
